import store from '@/store'
import * as Cookies from 'js-cookie'

export const connectWithAccountId = async (cable) => {
  let accountId = store.state.session.accountId
  if (accountId === null) {
    await store.dispatch('session/updateAccount', null, { root: true })
    accountId = store.state.session.accountId
  }
  const currentToken = Cookies.get('customer_token') || Cookies.get('token')
  const wsUrl = `/api/v3/cable?token=${currentToken}&account_id=${accountId}`
  cable.connection.disconnect()
  cable.connection.connect(wsUrl)
}
